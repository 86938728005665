
.footer-head--basic {
    color: #fff;
    background-color: var(--v-grey-darken4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 20px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-head--basic {
        padding: 30px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
