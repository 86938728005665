
.header::v-deep {
    .header__bg {
        min-height: 0;
    }
}
.header.gnb-active::v-deep {
    .header__bg {
        min-height: var(--header-bg-height);
    }
}
