
::v-deep {
    @media (min-width: 1024px) {
        #main {
            padding-top: 0 !important;
        }
    }
}
.main-section {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 1920px;
    margin: auto;
    &--special {
        background-image: url("/images/main/special-bg.jpg");
        background-position: left top;
    }
    &--contact {
        background-image: url("/images/main/contact-bg.jpg");
        background-position: center;
        aspect-ratio: 1920 / 960;
        max-width: 1920px;
        display: flex;
        align-items: center;
    }
}

.v-application .btn-special {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    &--01:hover {
        background-color: #fff !important;
        color: var(--v-grey-darken4) !important;
    }
    &--02:hover {
        background-color: var(--v-secondary-base) !important;
        border-color: var(--v-secondary-base) !important;
        color: #fff !important;
    }
}
@media (min-width: 768px) {
    .main-section--special {
        background-size: contain;
    }
}
