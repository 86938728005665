
.app--main .header-body--basic {
    background-color: rgba(0, 0, 0, 0.2);
}
// .header-body--basic {
//     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
// }
.gnb-active,
.scroll-active {
    .header-body--basic {
        background-color: rgba(0, 0, 0, 0.6);
    }
}
