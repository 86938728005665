
.lnb--all-opacity-black ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    > li {
        > a {
            color: rgba(255, 255, 255, 0.7);
            padding: 8px;
            &:hover {
                color: var(--v-tertiary-base);
            }
        }
    }
    .header__bg {
        min-height: 0;
    }
}
