
// main-visual
.main-visual {
    background-image: url("/images/main/main-visual.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1920px;
        width: 100%;
        height: 380px;
        max-height: 980px;
        margin: auto;
    }
    // &__img {
    //     max-width: calc(100% + 2px);
    //     width: calc(100% + 2px);
    //     height: 100%;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: -1;
    // }
    &__scrolldown {
        position: absolute;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        color: #fff;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            height: 400px;
        }
        &__scrolldown {
            position: absolute;
            bottom: 60px;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            min-height: 980px;
            height: 100vh;
        }
        &__scrolldown {
            bottom: 100px;
        }
    }
}
// @media (min-width: 1920px) {
//     .main-visual {
//         background-size: contain;
//     }
// }
[data-aos="slide-left"] {
    transform: translate3d(275%, 0, 0);
}

.logo-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    background: url("/images/main/logo-bg.png") no-repeat center;
    background-size: contain;
}

.visual-logo {
    filter: drop-shadow(0 0 10px #000);
}
